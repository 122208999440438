import ProductTable from "./ProductTable";

const Products = () => {

    return (
        <div style={{ width: "100%" }}>
            <ProductTable />
        </div>
    );
}

export default Products;