import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import Loading from "../../general/status/Loading";

const Result = () => {
    const { productId } = useParams();
    const { auth } = useAuth();
    const [err, setErr] = useState("");
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);


    useEffect(() => {
        getResult();
    }, [productId])

    const downloadCsv = async () => {
        setDownloadLoading(true);
        const URL = `/products/${productId}/result/csv`;
        try {
            const response = await axios.get(URL, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                responseType: 'blob',  // Specify the response type as blob
            });
            if (response.status !== 200) {
                setErr('Failed to fetch csv data');
            }
            const blob = new Blob([response.data], { type: 'text/csv' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = 'result.csv';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        } finally {
            setDownloadLoading(false);
        }
    }

    const getResult = async () => {
        setIsLoading(true);
        const URL = `/products/${productId}/result`;
        try {
            const { data } = await axios.get(
                URL,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            setResults(data);
        } catch (err) {
            console.log(err);
            if (err?.response.status === 418) {
                setErr(`⚠️ Missing ${err.response.data.name} in the ${err.response.data.location}, which is required for the result`)
            } else {
                setErr(err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };



    const ResultTable = ({ name, fields, results, score }) => {
        if (!score) {
            score = Object.keys(results[0]).map((propName) => results[3][propName] ?? 0).reduce((accumulator, currentValue) => {
                return accumulator + currentValue
            }, 0);
        }
        return (
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row justify-content-between align-items-center flex-grow-1">
                        <div className="col-md">
                            <h3>{name}</h3>
                        </div>
                        <div className="col-auto">
                            <h3>{"Result: " + score.toFixed(3)}</h3>
                        </div>
                    </div>
                </div>
                <table className="table table-text-center">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Abbreviation</th>
                            <th scope="col">Result</th>
                            <th scope="col">Result</th>
                            <th scope="col">Result</th>
                            <th scope="col">Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(results[0]).map((propName, i) => {
                            if (propName === "score") { return null; }
                            return (<tr>
                                <td scope="row">{i + 1}</td>
                                <td scope="row">{fields[i].name}</td>
                                <td scope="row">{fields[i].index}</td>
                                <td scope="row">{(results[0][propName] ?? 0).toFixed(3)}</td>
                                <td scope="row">{(results[1][propName] ?? 0).toFixed(3)}</td>
                                <td scope="row">{(results[2][propName] ?? 0).toFixed(3)}</td>
                                <td scope="row">{(results[3][propName] ?? 0).toFixed(3)}</td>
                            </tr>)
                        })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    const FunctionalityResult = ({ functions }) => {
        const functionalityFields = [
            { name: "Transport", index: "F1" },
            { name: "Opening", index: "F2" },
            { name: "Dosing", index: "F3" },
            { name: "Closing", index: "F4" },
            { name: "Storing", index: "F5" },
            { name: "Content", index: "F6" },
            { name: "Hygiene", index: "F7" },
            { name: "Residual Amount", index: "F8" },
            { name: "Safety", index: "F9" },
            { name: "Seperation by Customer", index: "F10" },
            { name: "Reusage by Customer", index: "F12" },
            { name: "Refill", index: "F13" },
            { name: "Life Prolongation", index: "F14" },
            { name: "Hygiene Seal", index: "F15" },
        ];


        return (
            <>
                <ResultTable name={"Functionality"} fields={functionalityFields} results={functions} score={functions[3].score} />
            </>
        )
    }

    const DeclarationResult = ({ declarations }) => {
        const declarationFields = [
            { name: "Shelf-Life", index: "D1" },
            { name: "Usage frequency", index: "D2" },
            { name: "Add features", index: "D3" },
            { name: "Recycling Code", index: "D4" },
            { name: "Packaging Labels", index: "D5" },
            { name: "Recycling", index: "D6" },
            { name: "Instructions", index: "D7" },
            { name: "Fake Packaging", index: "D8" },
            { name: "Biodegradable", index: "D9" },
            { name: "Compostable", index: "D10" },
        ]

        return (
            <>
                <ResultTable name={"Declaration"} fields={declarationFields} results={declarations} score={declarations[3].score} />
            </>
        )
    }

    const CircularityResult = ({ circularties }) => {
        const recyclateFields = [
            { name: "Compliance Minimum Standard", index: "CR1" },
            { name: "LUCID Registration", index: "CR2" },
            { name: "RecyClass-Rating", index: "CR3" },
            { name: "Recyling Rate", index: "CR4" },
            { name: "Number of Components", index: "CR5" },
            { name: "Separation At Recycler", index: "CR6" },
            { name: "Number of Colors", index: "CR7" },
            { name: "Area of Colors", index: "CR8" },
            { name: "Number of Ink Properties", index: "CR9" },
            { name: "Reusage", index: "CR10" },
            { name: "Shrink Foil", index: "CR11" },
            { name: "Black Material", index: "CR12" },
            { name: "Multi-Layer", index: "CR13" },
            { name: "Label Size", index: "CR14" },
            { name: "Label Type", index: "CR15" },
            { name: "Metallisation", index: "CR16" },
            { name: "Residual Amount", index: "CR17" },
        ];


        const wasteFields = [
            { name: "Accidification", index: "CW1" },
            { name: "Climate Change", index: "CW2" },
            { name: "Ecotoxicity: Freshwater", index: "CW3" },
            { name: "Energy Resources Non Renewable", index: "CW4" },
            { name: "Eutrophication: Freshwater", index: "CW5" },
            { name: "Eutrophication: Marine", index: "CW6" },
            { name: "Eutrophication: Terrestrial", index: "CW7" },
            { name: "Human Toxicity Carcinogenic", index: "CW8" },
            { name: "Human Toxicity Non-Carcinogenic", index: "CW9" },
            { name: "Ionising Radiation Human Health", index: "CW10" },
            { name: "Land use", index: "CW11" },
            { name: "Material Resources", index: "CW12" },
            { name: "Ozone Depletion", index: "CW13" },
            { name: "Particulate Matter Formation", index: "CW14" },
            { name: "Photochemical Ozone Formation", index: "CW15" },
            { name: "Water use", index: "CW16" },
            { name: "Heating value", index: "CW17" },
            { name: "Littering impact", index: "CW18" }
        ];
        console.log("circularities: " + JSON.stringify(circularties));
        return (
            <>
                <h1>Circularity</h1>
                <ResultTable name={"Recycling"} fields={recyclateFields} results={circularties.map((c) => c.recycling)} />
                <ResultTable name={"Waste"} fields={wasteFields} results={circularties.map((c) => c.waste)} />
            </>
        )
    }

    const MaterialResult = ({ materials }) => {
        const environmentFields = [
            { name: "Accidification", index: "ME1" },
            { name: "Climate Change", index: "ME2" },
            { name: "Ecotoxicity: Freshwater", index: "ME3" },
            { name: "Energy Resources Non Renewable", index: "ME4" },
            { name: "Eutrophication: Freshwater", index: "ME5" },
            { name: "Eutrophication: Marine", index: "ME6" },
            { name: "Eutrophication: Terrestrial", index: "ME7" },
            { name: "Human Toxicity Carcinogenic", index: "ME8" },
            { name: "Human Toxicity Non-Carcinogenic", index: "ME9" },
            { name: "Ionising Radiation Human Health", index: "ME10" },
            { name: "Land use", index: "ME11" },
            { name: "Material Resources", index: "ME12" },
            { name: "Ozone Depletion", index: "ME13" },
            { name: "Particulate Matter Formation", index: "ME14" },
            { name: "Photochemical Ozone Formation", index: "ME15" },
            { name: "Water use", index: "ME16" },
        ];

        const amountFields = [
            { name: "Total Weight / Packaging Weight ratio", index: "MA1" },
            { name: "Weight-Reduction", index: "MA2" },
            { name: "Size-Reduction", index: "MA3" },
            { name: "Mehrfachverpackung", index: "MA4" },
        ];

        const recyclateFields = [
            { name: "Typ", index: "MR1" },
            { name: "Herkunft", index: "MR2" },
            { name: "Inhalt", index: "MR3" },
        ];

        const transportFields = [
            { name: "Climate Change", index: "MT1" },
            { name: "Energy Resources Non Renewable", index: "MT2" },
        ];

        return (
            <>
                <h1>Material</h1>
                <ResultTable name={"Environment"} fields={environmentFields} results={materials.map((m) => m.environment)} />
                <ResultTable name={"Amount"} fields={amountFields} results={materials.map((m) => m.amount)} />
                <ResultTable name={"Recyclate"} fields={recyclateFields} results={materials.map((m) => m.recyclate)} />
                <ResultTable name={"Transport"} fields={transportFields} results={materials.map((m) => m.transport)} />
            </>
        )
    }

    const TotalScore = ({ result }) => {
        var rest_score = result.score;
        var stars = ["bi bi-star", "bi bi-star", "bi bi-star", "bi bi-star", "bi bi-star"];
        stars = stars.map((star) => {
            if (rest_score >= 20.0) {
                rest_score -= 20.0;
                return "bi bi-star-fill";
            }
            if (rest_score >= 10.0) {
                rest_score -= 10.0;
                return "bi bi-star-half";
            }
            return "bi bi-star";
        });


        return (
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row justify-content-between align-items-center flex-grow-1">
                        <div className="col-md">
                            <h3>{"Total Score: " + result.score.toFixed(3)}</h3>
                        </div>
                    </div>
                </div>
                <table className="table table-text-center">
                    <tbody>
                        <tr>
                            <td scope="row">Material</td>
                            <td scope="row">{result.material.score.toFixed(3)}</td>
                        </tr>
                        <tr>
                            <td scope="row">Functionality</td>
                            <td scope="row">{result.functionality.score.toFixed(3)}</td>
                        </tr>
                        <tr>
                            <td scope="row">Declaration</td>
                            <td scope="row">{result.declaration.score.toFixed(3)}</td>
                        </tr>
                        <tr>
                            <td scope="row">Circularity</td>
                            <td scope="row">{result.circularity.score.toFixed(3)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }



    return (
        <div style={{ width: "100%" }}>
            {isLoading ? (
                <Loading />
            ) : (
                err ? (
                    <div className={err ? "alert alert-soft-danger" : "offscreen"} role="alert">
                        {err}
                    </div>
                ) : (
                    <>
                        <div className="text-center" style={{ width: "100%", marginBottom: "25px" }}>
                            <button type="button" class="btn btn-ghost-primary" onClick={() => downloadCsv()}>{downloadLoading ? "Downloading..." : "Download as .csv"}</button>
                        </div>
                        {results.length > 0 && <TotalScore result={results[3]} />}
                        {results.length > 0 && <MaterialResult materials={results.map((r) => r.material)} />}
                        {results.length > 0 && <FunctionalityResult functions={results.map((r) => r.functionality)} />}
                        {results.length > 0 && <DeclarationResult declarations={results.map((r) => r.declaration)} />}
                        {results.length > 0 && <CircularityResult circularties={results.map((r) => r.circularity)} />}
                    </>
                )
            )}
        </div>
    );

}


export default Result;
