import UserTable from "./UserTable.js";
const Users = () => {

    return (
        <div style={{ width: "100%" }}>
            <UserTable/>
        </div>
    )
}

export default Users;
