import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import Loading from "../general/status/Loading";
import ProductStars from "../admin/ProductStars";
import placeholderImage from "../../assets/img/placeholder.jpg";

const SUR_CHARGE = 1.1;

function flattenObject(ob) {
    var toReturn = {};

    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object' && ob[i] !== null) {
            var flatObject = flattenObject(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;
                if (x === "score") { continue; }
                toReturn[i + '.' + x] = flatObject[x];
            }
        } else {
            if (i === "score") { continue; }
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}

const toMedal = (place) => {
    switch (place) {
        case 0:
            return <h1 style={{ fontSize: "32px" }}>🥇</h1>;
        case 1:
            return <h1 style={{ fontSize: "32px" }}>🥈</h1>;
        case 2:
            return <h1 style={{ fontSize: "32px" }}>🥉</h1>;
        default:
            return `#${place + 1}`;
    }
}

const ProductResultTable = ({ canSelect = true, results, maxScore, productImages, loadingProductImages, scoreIds, benchmark, selectedResults, setSelectedResults }) => {
    const { auth } = useAuth();
    const [folded, setFolded] = useState([true, true, true, true]);

    const selectResult = (id, checked) => {
        const resultIsSelected = selectedResults.find(u => u === id);
        if (checked && !resultIsSelected) {
            setSelectedResults(selectedResults => [...selectedResults, id]);
        } else if (!checked && resultIsSelected) {
            setSelectedResults(selectedResults.filter(u => u !== id));
        }
    }

    const FoldCategory = ({ name, id }) => {
        return (<h3 style={{ marginTop: "20px", marginBottom: "20px" }} onClick={() => {
            setFolded(folded => folded.map((f, i) => {
                if (i === id) { return !f; }
                return f;
            }))
        }}>{name + (folded[id] ? "   ◀️" : "   🔽")}</h3>)
    }

    const functionalityFields = [
        { name: "Transport", index: "F1" },
        { name: "Opening", index: "F2" },
        { name: "Dosing", index: "F3" },
        { name: "Closing", index: "F4" },
        { name: "Storing", index: "F5" },
        { name: "Content", index: "F6" },
        { name: "Hygiene", index: "F7" },
        { name: "Residual Amount", index: "F8" },
        { name: "Safety", index: "F9" },
        { name: "Seperation by Customer", index: "F10" },
        { name: "Reusage by Customer", index: "F12" },
        { name: "Refill", index: "F13" },
        { name: "Life Prolongation", index: "F14" },
        { name: "Hygiene Seal", index: "F15" },
    ];


    const declarationFields = [
        { name: "Shelf-Life", index: "D1" },
        { name: "Usage frequency", index: "D2" },
        { name: "Add features", index: "D3" },
        { name: "Recycling Code", index: "D4" },
        { name: "Packaging Labels", index: "D5" },
        { name: "Recycling", index: "D6" },
        { name: "Instructions", index: "D7" },
        { name: "Fake Packaging", index: "D8" },
        { name: "Biodegradable", index: "D9" },
        { name: "Compostable", index: "D10" },
    ]

    const circularityFields = [
        { name: "Compliance Minimum Standard", index: "RR1" },
        { name: "LUCID Registration", index: "RR2" },
        { name: "RecyClass-Rating", index: "RR3" },
        { name: "Recyling Rate", index: "RR4" },
        { name: "Number of Components", index: "RR5" },
        { name: "Separation At Recycler", index: "RR6" },
        { name: "Number of Colors", index: "RR7" },
        { name: "Area of Colors", index: "RR8" },
        { name: "Number of Ink Properties", index: "RR9" },
        { name: "Reusage", index: "RR10" },
        { name: "Shrink Foil", index: "RR11" },
        { name: "Black Material", index: "RR12" },
        { name: "Multi-Layer", index: "RR13" },
        { name: "Label Size", index: "RR14" },
        { name: "Label Type", index: "RR15" },
        { name: "Metallisation", index: "RR16" },
        { name: "Residual Amount", index: "RR17" },
        { name: "Accidification", index: "RW1" },
        { name: "Climate Change", index: "RW2" },
        { name: "Ecotoxicity: Freshwater", index: "RW3" },
        { name: "Energy Resources Non Renewable", index: "RW4" },
        { name: "Eutrophication: Freshwater", index: "RW5" },
        { name: "Eutrophication: Marine", index: "RW6" },
        { name: "Eutrophication: Terrestrial", index: "RW7" },
        { name: "Human Toxicity Carcinogenic", index: "RW8" },
        { name: "Human Toxicity Non-Carcinogenic", index: "RW9" },
        { name: "Ionising Radiation Human Health", index: "RW10" },
        { name: "Land use", index: "RW11" },
        { name: "Material Resources", index: "RW12" },
        { name: "Ozone Depletion", index: "RW13" },
        { name: "Particulate Matter Formation", index: "RW14" },
        { name: "Photochemical Ozone Formation", index: "RW15" },
        { name: "Water use", index: "RW16" },
        { name: "Heating value", index: "RW17" },
        { name: "Littering impact", index: "RW18" }
    ];


    const materialFields = [
        { name: "Accidification", index: "ME1" },
        { name: "Climate Change", index: "ME2" },
        { name: "Ecotoxicity: Freshwater", index: "ME3" },
        { name: "Energy Resources Non Renewable", index: "ME4" },
        { name: "Eutrophication: Freshwater", index: "ME5" },
        { name: "Eutrophication: Marine", index: "ME6" },
        { name: "Eutrophication: Terrestrial", index: "ME7" },
        { name: "Human Toxicity Carcinogenic", index: "ME8" },
        { name: "Human Toxicity Non-Carcinogenic", index: "ME9" },
        { name: "Ionising Radiation Human Health", index: "ME10" },
        { name: "Land use", index: "ME11" },
        { name: "Material Resources", index: "ME12" },
        { name: "Ozone Depletion", index: "ME13" },
        { name: "Particulate Matter Formation", index: "ME14" },
        { name: "Photochemical Ozone Formation", index: "ME15" },
        { name: "Water use", index: "ME16" },
        { name: "Total Weight / Packaging Weight ratio", index: "MA1" },
        { name: "Weight-Reduction", index: "MA2" },
        { name: "Size-Reduction", index: "MA3" },
        { name: "Mehrfachverpackung", index: "MA4" },
        { name: "Typ", index: "MR1" },
        { name: "Herkunft", index: "MR2" },
        { name: "Inhalt", index: "MR3" },
        { name: "Climate Change", index: "MT1" },
        { name: "Energy Resources Non Renewable", index: "MT2" },
    ];


    const TableColumn = ({ fields, results, productIds }) => {
        return Object.keys(flattenObject(results[0])).map((propName, i) => {
            return (<>
                <tr>
                    {results.map((r, f) => {
                        var isBenchmark = productIds[f] === benchmark;
                        return (
                            <td scope="row" style={{ borderTop: isBenchmark ? "none" : "rgba(231, 234, 243, 0.7) solid 1px", borderBottom: "none", backgroundColor: isBenchmark ? '#b8f5b8' : 'white' }}>{f === 0 && (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h5 style={{ marginTop: "10px", marginBottom: "5px" }} >{fields[i].name}</h5>
                                    <a style={{ marginLeft: "10px", marginTop: "8px", fontSize: "0.875rem" }} >{fields[i].index}</a>
                                </div>
                            )}</td>)
                    })}
                </tr>
                <tr>
                    {results.map((r, f) => {
                        var isBenchmark = productIds[f] === benchmark;
                        var flat = flattenObject(r);
                        return (
                            <td scope="row" style={{ borderTop: isBenchmark ? "none" : "rgba(231, 234, 243, 0.7) solid 1px", borderBottom: "none", backgroundColor: isBenchmark ? '#b8f5b8' : 'white' }}>{(flat[propName] ?? 0).toFixed(3)}</td>)
                    })}
                </tr>
            </>)
        })
    }

    return (
        <table className="table table-text-center">
            <tbody>
                <tr>
                    {results.map((r) => {
                        return (<>
                            <td scope="row" style={{ border: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white', borderTopLeftRadius: "0.75rem", borderTopRightRadius: "0.75rem" }}>
                                <h5>{benchmark === r.product.id && "Benchmark"}</h5>
                            </td>
                        </>)
                    })}
                </tr>
                {canSelect && <tr>
                    {results.map((r) => {
                        return (<>
                            <td style={{ border: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white', minWidth: "200px" }}>
                                <input type="checkbox" className="form-check-input" checked={selectedResults.includes(r.product.id)}
                                    onChange={event => selectResult(r.product.id, event.target.checked)}
                                />
                            </td>
                        </>)
                    })}
                </tr>}
                <tr>
                    {results.map((r) => {
                        return (<>
                            <td scope="row" style={{ border: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>
                                {/*<h5>Place:</h5>*/}
                                {toMedal(scoreIds.indexOf(r.product.id))}
                            </td>
                        </>)
                    })}
                </tr>
                <tr>
                    {results.map((r) => {
                        return (<>
                            <td scope="row" style={{ border: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>
                                {loadingProductImages.get(r.product.id) ? <Loading /> : (<img
                                    className="img-fluid"
                                    width={"200px"}
                                    src={productImages.get(r.product.id) ?? placeholderImage}
                                    alt="Produktbild"
                                />)}
                            </td>
                        </>)
                    })}
                </tr>
                <tr>
                    {results.map((r) => {
                        return (<>
                            <td scope="row" style={{ border: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white', color: "#089c44" }}>
                                Algo {r.product.algorithm_version}
                            </td>
                        </>)
                    })}
                </tr>

                <tr>
                    {results.map((r) => {
                        return (<>
                            <td scope="row" style={{ border: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>
                                <Link to={"../product/" + r.product.id}>
                                    <h5>{r.product.name}</h5>
                                </Link>
                            </td>
                        </>)
                    })}
                </tr>

                <tr>
                    {results.map((r) => {
                        return (<>
                            <td scope="row" style={{ border: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>
                                {r.product.is_optimized ? (<h5 style={{ color: "#089c44" }}><i className="bi bi-tools" /> DIGITAL TWIN</h5>)
                                    : (<h5 class="card-subtitle mb-2">PRODUCT</h5>)}
                            </td>
                        </>)
                    })}
                </tr>

                <tr>
                    {results.map((r) => {
                        return (<>
                            <td scope="row" style={{ border: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>
                                {r.product.company_name ?? "-"}
                            </td>
                        </>)
                    })}
                </tr>



                <tr>
                    {results.map((r) => {
                        return (<>
                            <td scope="row" style={{ border: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>
                                {r.product.gtin ?? "-"}
                            </td>
                        </>)
                    })}
                </tr>
                <tr>
                    {results.map((r) => {
                        return (
                            <td scope="row" style={{ border: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>< ProductStars score={r.result.score} jump={maxScore / 10} /></td>)
                    })
                    }
                </tr>
                {results.map((r, i) => {
                    return (
                        <td scope="row" style={{ borderTop: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>{i === 0 && <FoldCategory name={"Material"} id={0} />}</td>)
                })}
                {!folded[0] && <TableColumn fields={materialFields} results={results.map((r) => r.result.material)} productIds={results.map((r) => r.product.id)} />}
                <tr>
                    {results.map((r) => {
                        return (
                            <td scope="row" style={{ borderTop: benchmark === r.product.id ? "none" : "rgba(231, 234, 243, 0.7) solid 1px", borderColor: folded[0] ? "rgba(231, 234, 243, 0.7)" : "black", borderBottom: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}><h5>{r.result.material.score.toFixed(3)}</h5></td>)
                    })}
                </tr>
                {results.map((r, i) => {
                    return (
                        <td scope="row" style={{ borderTop: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>{i === 0 && <FoldCategory name={"Functionality"} id={1} />}</td>)
                })}
                {!folded[1] && <TableColumn fields={functionalityFields} results={results.map((r) => r.result.functionality)} productIds={results.map((r) => r.product.id)} />}
                <tr>
                    {results.map((r) => {
                        return (
                            <td scope="row" style={{ borderTop: benchmark === r.product.id ? "none" : "rgba(231, 234, 243, 0.7) solid 1px", borderColor: folded[1] ? "rgba(231, 234, 243, 0.7)" : "black", borderBottom: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}><h5>{r.result.functionality.score.toFixed(3)}</h5></td>)
                    })}
                </tr>
                {results.map((r, i) => {
                    return (
                        <td scope="row" style={{ borderTop: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>{i === 0 && <FoldCategory name={"Declaration"} id={2} />}</td>)
                })}
                {!folded[2] && <TableColumn fields={declarationFields} results={results.map((r) => r.result.declaration)} productIds={results.map((r) => r.product.id)} />}
                <tr>
                    {results.map((r) => {
                        return (
                            <td scope="row" style={{ borderTop: benchmark === r.product.id ? "none" : "rgba(231, 234, 243, 0.7) solid 1px", borderColor: folded[2] ? "rgba(231, 234, 243, 0.7)" : "black", borderBottom: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }} ><h5>{r.result.declaration.score.toFixed(3)}</h5></td>)
                    })}
                </tr>
                {results.map((r, i) => {
                    return (
                        <td scope="row" style={{ borderTop: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>{i === 0 && <FoldCategory name={"Circularity"} id={3} />}</td>)
                })}
                {!folded[3] && <TableColumn fields={circularityFields} results={results.map((r) => r.result.circularity)} productIds={results.map((r) => r.product.id)} />}
                <tr>
                    {results.map((r) => {
                        return (
                            <td scope="row" style={{ borderTop: benchmark === r.product.id ? "none" : "rgba(231, 234, 243, 0.7) solid 1px", borderColor: folded[3] ? "rgba(231, 234, 243, 0.7)" : "black", borderBottom: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}><h5>{r.result.circularity.score.toFixed(3)}</h5></td>)
                    })}
                </tr>
                {results.map((r, i) => {
                    return (
                        <td scope="row" style={{ borderTop: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white' }}>{i === 0 && <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>Total Score</h2>}</td>)
                })}
                <tr>
                    {results.map((r) => {
                        return (
                            <td scope="row" style={{ borderTop: "solid 2px", borderBottom: "none", backgroundColor: benchmark === r.product.id ? '#b8f5b8' : 'white', borderBottomLeftRadius: "0.75rem", borderBottomRightRadius: "0.75rem" }}><h5>{r.result.score.toFixed(3)}</h5></td>)
                    })}
                </tr>
            </tbody>
        </table>

    )
}

export default ProductResultTable;
