import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import Loading from "../general/status/Loading";
import Modal from "../general/Modal"
import GhostError from "../general/status/GhostError";
import Warning from "../general/status/Warning";
import LoadingAndErrIndicator from "../general/status/LoadingAndErrorIndicator";

const CLONE_PRODUCTS_URL = '/products/clone';

const CloneProductModal = ({ productIds, jobId, reload, handleClose }) => {
  const { auth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [productName, setProductName] = useState("");
  const [areOptimized, setAreOptimized] = useState(false);
  const navigate = useNavigate();

  const cloneProducts = async (jobId) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        CLONE_PRODUCTS_URL,
        {
          jobId: jobId ? parseInt(jobId) : null,
          productIds: productIds,
          areOptimizations: areOptimized
        },
        {
          headers: {
            'Authorization': `Bearer ${auth.accessToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      reload();
      handleClose();
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal title={"Clone Products"} handleClose={handleClose}>
      <div className="modal-body">
        {err || isLoading ? (<LoadingAndErrIndicator err={err} isLoading={isLoading} loadingMessage={"Cloning products..."} />) :
          (
            <div className="form-check">
              <input type="checkbox" id="123434" className="form-check-input" checked={areOptimized} onChange={() => { setAreOptimized(!areOptimized) }}></input>
              <label className="form-check-label" htmlFor="123434">these are optimizations</label>
            </div>
          )}
      </div>
      <div className="modal-footer">
        {/*<button type="button" onClick={handleClose} className="btn btn-white" data-bs-dismiss="modal">
              Close
            </button>*/}

        <div class="row justify-content-between align-items-center mx-auto">
        {jobId && (<div class="col-auto py-1">
            <button type="button" onClick={() => cloneProducts(jobId)} className="btn btn-outline-primary">Clone Products inside Jobs</button>
          </div>)}

          <div class="col-auto py-1">
            <button type="button" onClick={() => cloneProducts()} className="btn btn-outline-primary">Clone Products</button>
          </div>
        </div>
      </div>
    </Modal >
  )
}

export default CloneProductModal
