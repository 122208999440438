const ProductStars = ({score, jump}) => {
    var stars = ["bi bi-star", "bi bi-star", "bi bi-star", "bi bi-star", "bi bi-star"];
    stars = stars.map((star) => {
        if (score >= jump * 2) {
            score -= jump * 2;
            return "bi bi-star-fill";
        }
        if (score >= jump) {
            score -= jump;
            return "bi bi-star-half";
        }
        return "bi bi-star";
    });


    return (
        <>
            {stars.map((star) => {
                return (<i className={star} style={{fontSize: "1rem", color: "#08984A"}}></i>)
            })}
        </>
    )
}

export default ProductStars;