import React, { useContext, useState } from 'react'
import { FormContext } from '../general/FormContext';

const Input = ({ id, label, placeholder, value, prefix, info }) => {
    const { handleChange } = useContext(FormContext)
    const [infoOpen, setInfoOpen] = useState(false);
    return (
        <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">{label + " " + (prefix ? `(${prefix})` : "")}</label>
            {info && <i class={infoOpen ? "bi bi-x-circle" : "bi bi-info-circle"} onClick={() => setInfoOpen(infoOpen => !infoOpen)} style={{ color: "rgb(8, 156, 68)", fontSize: "1rem", verticalAlign: "middle", marginLeft: "5px" }} />}
            {infoOpen && <p>{info}</p>}
            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                placeholder={placeholder ? placeholder : ''}
                value={value}
                onChange={event => handleChange(id, event)}
            />
        </div>
    )
}

export default Input