import { useNavigate, Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthProvider.js";
import { Outlet } from 'react-router-dom';
import React from 'react'
import axios from "../../api/axios.js"
import ROLES from "../../App.js"
import logo from "../../assets/img/susy_logo.png";

//const LOGOUT_URL = "/logout"

const ADMIN_NAV_ITEMS = [
    {   
        name: "Administration" ,
        children: [
            { name: "Users", to: "./users", icon: "bi bi-people"},
            { name: "Labs", to: "./labs", icon: "bi bi-droplet-half"},
        ]
    },
    {   
        name: "Jobs & Products",
        children: [
            { name: "Jobs", to: "./", icon: "bi bi-clipboard-data"},
            { name: "Products", to: "./products", icon: "bi bi-bag"},
        ]
    },
    {   
        name: "Algorithm",
        children: [
            { name: "Materials", to: "./materials", icon :"bi bi-tree"},
            { name: "Weights", to: "./weighting", icon: "bi bi-bezier2"}
        ]
    },
];



const LAB_NAV_ITEMS = [
    {
        name: "Jobs" ,
        children: [
            { name: "Jobs", to: "./", icon:"bi bi-clipboard-data"}
        ]
    },
];


const CUSTOMER_NAV_ITEMS = [
    {
        name: "Jobs" ,
        children: [
            { name: "Jobs", to: "./", icon:"bi bi-clipboard-data"}
        ]
    },
];


const Dashboard = ({ role }) => {
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');
    const [navItems, setNavItems] = useState([]);

    useEffect(() => {
        switch (role) {
            case 0:
                setNavItems(ADMIN_NAV_ITEMS);
                break;
            case 1:
                setNavItems(LAB_NAV_ITEMS);
                break;
            case 2:
                setNavItems(CUSTOMER_NAV_ITEMS);
                break;
        }
    }, [role]);
    const logout = async () => {
        localStorage.removeItem("auth");
        setAuth({});
        navigate('/login');
    };

    const HorizontalNavBar = () => {
        return (
            <header className="docs-navbar navbar navbar-expand-lg navbar-end navbar-light bg-white" style={{ borderBottom: "0.0625rem solid rgba(231, 234, 243, 0.7)" }}>
                <div className="container">
                    <div className="js-mega-menu navbar-nav-wrap">
                        {/* Logo */}
                        <Link to="/home" style={{ display: "block", marginTop: "0.5rem" }}>
                            <img className="navbar-brand-logo mx-auto" src={logo} alt="Logo" data-hs-theme-appearance="default" style={{ display: "block" }}></img>
                        </Link>
                        {/* End Logo */}
                        <nav
                            className="navbar-nav-wrap-col collapse navbar-collapse"
                            id="navbarNavMenuWithMegaMenu"
                        >
                            {/* Navbar */}
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href="#">
                                        Menu
                                    </a>
                                </li>
                            </ul>
                            {/* End Navbar */}
                        </nav>
                    </div>
                </div>
            </header>
        )
    }

    const accountName = () => {
        switch (role) {
            case 0:
                return "Admin-Account";
            case 1:
                return "Labor-Account";    
            case 2:
                return "Kunden-Account";
                
        }
    }

    const VerticalNavBar = () => {
        return (
            <div className="navbar-vertical-container" style={{ backgroundColor: "white", height: "100%", width: "15rem", zIndex: 100, position: "fixed", borderRight: "0.0625rem solid rgba(231, 234, 243, 0.7)" }}>
                <div className="navbar-vertical-content">

                    <Link to="/home" style={{ width: "100%", display: "block", marginTop: "2rem" }}>
                        <img className="navbar-brand-logo mx-auto" src={logo} alt="Logo" data-hs-theme-appearance="default" style={{ display: "block" }}></img>
                        <h1 class="display-5" style={{textAlign: 'center', fontSize: "12px", paddingTop: '10px'}}>{accountName()}</h1>
                    </Link>

                    <div
                        className="navbar-expand-lg navbar-vertical mb-3 mb-lg-5"
                        style={{ maxWidth: "15rem" }}
                    >
                        {/* Navbar Collapse */}
                        <div
                            id="navbarVerticalNavMenuCardTabs"
                            className="collapse navbar-collapse"
                        >
                            <div
                                id="navbarSettingsCardWithNavTab"
                                className="card-navbar-nav nav nav-tabs nav-vertical"
                            >

                                {navItems.map((item, i) => (
                                        <div key={i * 120 + 160}>
                                            <span className="dropdown-header">{item.name}</span>
                                            {item.children.map((link, i) => (
                                            <Link to={link.to} key={i}>
                                                <a className="nav-link" href="#" style={{ paddingLeft: "40px" }}>
                                                    <i className={ link.icon + " nav-icon"} /> {link.name}
                                                </a>
                                            </Link>
                                    )
                                )}
                                        </div>
                                    )
                                )}

                                <span className="dropdown-header">Account</span>
                                <Link to="address">
                                    <a className="nav-link" href="#" style={{ paddingLeft: "40px" }}>
                                        <i className="bi bi-file-earmark-person nav-icon" /> Personal Data
                                    </a>
                                </Link>
                                <a className="nav-link" onClick={logout} style={{ cursor: "pointer", paddingLeft: "40px"  }}>
                                    <i className="bi-box-arrow-right nav-icon"></i> Log Out
                                </a>
                            </div>
                        </div>
                        {/* End Navbar Collapse */}
                    </div>
                    {/* End Navbar */}

                </div>
            </div>
        )
    }

    return (
        <div>
            {true ? (
                <div>
                    <VerticalNavBar></VerticalNavBar>
                    <div style={{ marginLeft: "15rem", padding: "20px", display: "flex" }}>
                        <Outlet></Outlet>
                    </div>
                </div>
            ) : (
                <div>
                    <HorizontalNavBar></HorizontalNavBar>
                    <div style={{ padding: "20px", display: "flex"}}>
                        <Outlet></Outlet>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Dashboard;
