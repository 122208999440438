import GhostError from "./GhostError"
import Loading from "./Loading"

const LoadingAndErrIndicator = ({err, isLoading, loadingMessage}) => {
    return err || isLoading ? (<div className="my-4">
        {isLoading && (<Loading desc={loadingMessage ?? "Loading..."} />)}
        {err && <GhostError err={err}/>}
    </div>) : (<></>)
}

export default LoadingAndErrIndicator