
import { useParams } from "react-router-dom";



const User = () => {
    const { userId } = useParams();

    return (
        <div>
            <h1> userId: {userId}</h1>
            <a>Email-Adresse: email@email.com</a>
        </div>
    );
}




export default User;