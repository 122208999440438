import { useRef, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PasswordField from '../PasswordField';


//import logo from "../assets/svg/logos/logo.svg";
import logo from "../../assets/img/susy_logo.png";
import backgroundImage from "../../assets/img/background.png";
import { ROLES } from "../../App.js"
import { useParams } from 'react-router-dom';
//import ReactCodeInput from 'react-verification-code-input';

import axios from '../../api/axios';
import ReactCodeInput from "react-code-input";

const SIGNUP_URL = '/signup';
const INVITATION_STILL_ALIVE_URL = '/invitation/still-alive';



const Signup = () => {
  const { invite_id } = useParams();
  const { setAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const emailRef = useRef();
  const errRef = useRef();

  const [pin, setPin] = useState('');
  const [invite, setInvite] = useState(null);
  const [isInviteValid, setInviteValid] = useState(false);
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [consent, setConsent] = useState(false);
  const [isInInviteCheck, setIsInInviteCheck] = useState(true);

  useEffect(() => {
    if (invite_id && !invite) {
      checkInvite()
    }
    //emailRef.current.focus();
    setErrMsg('');
  }, [email, pwd, invite_id])



  const checkInvite = async (pinCode) => {
    setIsLoading(true);
    let body = {};
    if (invite_id) {
      body.longIdentifier = invite_id;
    } else if (pinCode) {
      body.shortIdentifier = pinCode;
    }
    try {
      const { data } = await axios.post(INVITATION_STILL_ALIVE_URL,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        }
      );
      switch (data.role) {
        case 0:
          console.log('Admin invite');
          break;
        case 1:
          console.log('Laboratory Invite');
          break;
        case 2:
          console.log('Customer Invite');
          break;
        default:
          console.log('No invite')
      }
      setInvite(data);
      setIsInInviteCheck(false);
    } catch (err) {
      console.log(err.response);
      if (!err?.response) {
        setErrMsg('No server connection');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing email or password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        console.log("Error Message: ", err.response.data)
        setErrMsg('Login failed: ' + err);
      }
      errRef.current.focus();
    } finally {
      setIsLoading(false);
    }
  }


  const handleSubmit = async (e) => {
    if (isInInviteCheck) {
      checkInvite();
      return;
    }
    e.preventDefault();
    if (!consent) {
      setErrMsg('Das Zustimmen der Datenschutzerklärung ist erforderlich.');
      errRef.current.focus()
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(SIGNUP_URL,
        {
          invite_short_identifier: invite.short,
          invite_long_identifier: invite.long,
          email: email,
          password: pwd
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        }
      );
      const accessToken = response?.data?.accessToken;
      const role = response?.data?.role;
      setAuth({ role, accessToken });
      localStorage.setItem("auth", JSON.stringify({ role, accessToken }));
      setEmail('');
      setPwd('');
      if (role === ROLES.Admin) {
        navigate('/admin', { replace: true });
      } else if (role === ROLES.Laboratory) {
        navigate('/lab', { replace: true });
      } else if (role === ROLES.Customer) {
        navigate('/customer', { replace: true });
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No server connection');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing email or password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        console.log("Error Message: ", err.response.data)
        setErrMsg('Login failed: ' + err);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handlePinChange = pinCode => {
    setPin(pinCode);
    if (pinCode.length > 5) {
      checkInvite(pinCode);
    }
  };

  const CodeInput = () => {
    return (
      <ReactCodeInput
        id="pinCode"
        type="text"
        isValid={isInviteValid}
        fields={6}
        onChange={handlePinChange}
        value={pin}
      />
    );
  }

  const props = {
    className: "reactCodeInput",
    inputStyle: {
      "fontFamily": "monospace",
      "MozAppearance": "textfield",
      "borderRadius": "6px",
      "border": "1px solid",
      "boxShadow": "0px 0px 10px 0px rgba(0,0,0,.10)",
      "margin": "4px",
      "paddingLeft": "8px",
      "width": "36px",
      "height": "45px",
      "fontSize": "32px",
      "boxSizing": "border-box",
      "color": "black",
      "backgroundColor": "white",
      "borderColor": "lightgrey",
      "textAlign": "center",
      "fontWeight": "bold"
    },
    inputStyleInvalid: {
      "fontFamily": "monospace",
      "MozAppearance": "textfield",
      "borderRadius": "6px",
      "border": "1px solid",
      "boxShadow": "0px 0px 10px 0px rgba(0,0,0,.05)",
      "margin": "4px",
      "paddingLeft": "8px",
      "width": "37px",
      "height": "45px",
      "fontSize": "32px",
      "boxSizing": "border-box",
      "color": "black",
      "backgroundColor": "white",
      "borderColor": "lightgrey",
      "textAlign": "left",
      "fontWeight": "bold"
    }
  }


  const Form = () => {
    return (
      <>
        <div className="mb-4">
          <label className="form-label" htmlFor="signinSrEmail">
            Email
          </label>
          <input
            type="text"
            key="email"
            className="form-control form-control-lg"
            name="username"
            ref={emailRef}
            id="signinSrEmail"
            onChange={(e) => setEmail(e.target.value)}
            tabIndex={1}
            value={email}
            placeholder=""
            aria-label="00000"
            required=""
          />
          <span className="invalid-feedback">
            Please enter a valid email address.
          </span>
        </div>
        <div className="mb-4">
          <label
            className="form-label w-100"
            htmlFor="signupSrPassword"
            tabIndex={0}
          >
            <span>Passwort</span>
          </label>
          <PasswordField value={pwd} onChange={(v) => setPwd(v)}/>
          <span className="invalid-feedback">
            Bitte gebe ein valides Passwort ein.
          </span>
        </div>
        {/* Form Check */}
        <div className="form-check mb-4">
          <input
            className="form-check-input"
            key="consent" 
            type="checkbox"
            defaultValue=""
            onChange={(e) => setConsent(e.currentTarget.checked)}
            id="termsCheckbox"
          />
          <label className="form-check-label mt-1" htmlFor="termsCheckbox">
            Ich stimme der
            <a
              className="form-label-link mb-0"
              href="https://susycheck.eu/en/data-protection/"
              target="_blank"
              rel="noreferrer"
            >
              &nbsp;Datenschutzerklärung&nbsp;
            </a>
            zu.
          </label>
        </div>
      </>
    );
  }

  return (
    <main id="content" role="main" className="main">
      <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          top: 0,
          left: 0,
          height: "100%",
          backgroundColor: "#f3f5f7",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover'
        }}
      >
      </div>
      {/* Content */}
      <div className="container py-5 py-sm-7">
        <Link to="/"><a
          className="d-flex justify-content-center mb-5"
        >
          <img
            className="zi-2"
            src={logo}
            alt="Image Description"
            style={{ width: "8rem" }}
          />
        </a></Link>
        <div className="mx-auto" style={{ maxWidth: "30rem" }}>
          {/* Card */}
          <div className="card card-lg mb-5">
            <div className="card-body">
              {/* Form */}
                <div className="text-center">
                  <div className="mb-5">
                    <h1 className="display-5">Registrierung</h1>
                    <div ref={errRef} className={errMsg ? "alert alert-soft-danger" : "offscreen"} role="alert">
                      {errMsg}
                    </div>
                    <p>
                    {isInInviteCheck ? "Um dich zu registrieren, gib bitte deinen 6-stelligen Code unten ein. 😊" : "Gebe hier deine Daten ein"}
                    </p>
                  </div>
                  {/*<span className="divider-center text-muted mb-4">OR</span>*/}
                </div>
                {/* Form */}
                {isInInviteCheck && (<div className="mb-3 mb-lg-5" style={{ margin: "auto", width: "270px" }}>
                  <ReactCodeInput
                    id="pinCode"
                    type="text"
                    isValid={isInviteValid}
                    fields={6}
                    onChange={handlePinChange}
                    value={pin}
                    forceUppercase
                    {...props}
                  />
                </div>)}
                <form className="js-validate needs-validation" noValidate="" onSubmit={handleSubmit}>
                  {!isInInviteCheck && Form()}
                {/* End Form */}
                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-lg" style={{ height: "50px" }}>
                      {isLoading ? <div className="lds-ripple"><div></div><div></div></div> : (isInInviteCheck ? "Weiter" : "Registrieren")}
                    </button>
                  </div>
                </form>
                <div className="form-check mt-2">
                  <label className="form-check-label mt-1" htmlFor="termsCheckbox">
                    Du hast bereits ein Account, dann 
                    <a
                      className="form-label-link mb-0"
                      href="/login/" 
                      rel="noreferrer"
                    >
                      &nbsp;klicke hier&nbsp;
                    </a>
                       😊
                  </label>
                </div>
              {/* End Form */}
            </div>
          </div>
          {/* End Card */}
        </div>
      </div>
      {/* End Content */}
    </main>
  )
}

export default Signup
