import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import moment from "moment";
import SearchBar from "../general/SearchBar";
import AddProductModal from "./AddProductModal";
import LoadingAndErrIndicator from "../general/status/LoadingAndErrorIndicator";
import RemoveProductsModal from "./RemoveProductsModal";
import CloneProductModal from "./CloneProductsModal";
import { ROLES } from "../../App";
import ImportProductsModal from "./ImportProductsModal";

const JOB_URL = '/jobs';
const ProductTable = ({ jobId }) => {
    const { auth } = useAuth();
    const [productData, setProductData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState("");
    const [err, setErr] = useState('');
    const [selectAllProducts, setSelectAllProducts] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isRemoveProductsModalOpen, setIsRemoveProductsModalOpen] = useState(false);
    const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
    const [isCloneProductsModalOpen, setIsCloneProductsModalOpen] = useState(false);
    const [isImportProductsModalOpen, setIsImportProductsModalOpen] = useState(false);

    useEffect(() => {
        getProducts();
    }, []);


    const getProducts = async () => {
        setIsLoading(true);
        const url = jobId ? `${JOB_URL}/${jobId}/products` : `/products`
        try {
            const { data } = await axios.get(
                url,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            const mappedData = data
                .sort((a, b) => (a.name > b.name) ? 1 : -1)
                .map((product, n) => {
                    return {
                        number: n + 1,
                        id: product.id,
                        name: product.name,
                        gtin: product.gtin,
                        company_name: product.company_name,
                        is_optimized: product.is_optimized,
                        createdAt: product.createdAt
                    }
                })

            setSearchData(mappedData);
            setProductData(mappedData);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        if (e.target.value.length > 0) {
            let results = productData.filter((product) => {
                return matches(product, e.target.value);
            });
            setSearchData(results)
        } else {
            setSearchData(productData)
        }
    }

    function matches(product, search) {
        const name = product?.name?.trim().toLowerCase() ?? "";
        const gtin = product?.gtin?.trim().toLowerCase() ?? "";
        const companyName = product?.company_name?.trim().toLowerCase() ?? "";
        const s = search.trim().toLowerCase();
        return name.includes(s) || gtin.includes(s) || companyName.includes(s);
    }

    const selectProduct = (id, checked) => {
        const productIsSelected = selectedProducts.find(p => p === id);
        if (checked && !productIsSelected) {
            setSelectedProducts(selectedProducts => [...selectedProducts, id]);
        } else if (!checked && productIsSelected) {
            setSelectedProducts(selectedProducts.filter(p => p !== id));
        }
    }

    const selectAll = (checked) => {
        setSelectAllProducts(checked);
        if (checked) {
            setSelectedProducts(productData.map(u => u.id));
        } else {
            setSelectedProducts([]);
        }
    }

    const Selection = () => {
        return (
            <div className="d-flex align-items-center">
                <span className="fs-5 me-3">
                    <span id="datatableCounter">{selectedProducts.length + " "}</span>
                    selected
                </span>
                <button type="button" class="btn btn-soft-primary" onClick={() => setIsCloneProductsModalOpen(true)}>
                    <i className="bi bi-copy" /> Clone
                </button>
                <div style={{ width: "20px" }}></div>
                <a className="btn btn-outline-danger btn-sm" href="javascript:;" onClick={() => setIsRemoveProductsModalOpen(true)}>
                    <i className="bi-trash" /> Remove
                </a>
            </div>
        );
    }

    const reload = () => {
        setSelectAllProducts(false);
        setSelectedProducts([]);
        getProducts();
    }


    return (<>
        {isRemoveProductsModalOpen && <RemoveProductsModal handleClose={() => setIsRemoveProductsModalOpen(false)} productIds={selectedProducts} jobId={jobId} reload={reload} />}
        {isNewProductModalOpen && <AddProductModal handleClose={() => setIsNewProductModalOpen(false)} jobId={jobId} />}
        {isCloneProductsModalOpen && <CloneProductModal handleClose={() => setIsCloneProductsModalOpen(false)} jobId={jobId} productIds={selectedProducts} reload={reload} />}
        {isImportProductsModalOpen && <ImportProductsModal handleClose={() => setIsImportProductsModalOpen(false)} jobId={jobId} productIds={selectedProducts} reload={reload} />}
        <div className="card mb-3 mb-lg-5">
            <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                    <div className="col-md">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 class="card-header-title">Products</h3>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="row align-items-sm-center">
                            <div className="col-sm-auto">
                                {selectedProducts.length > 0 && <Selection />}
                            </div>
                            <div className="col-sm-auto">
                                <button type="button" onClick={() => setIsNewProductModalOpen(true)} className="btn btn-ghost-primary">
                                    <i class="bi bi-clipboard-plus"></i> Create Product
                                </button>
                                {jobId && auth.role === ROLES.Admin && <button type="button" onClick={() => setIsImportProductsModalOpen(true)} className="btn btn-ghost-primary">
                                    <i class="bi bi-clipboard-plus"></i> Import Product
                                </button>}
                            </div>
                            <div className="col-md">
                                <SearchBar handleChange={handleChange} searchInput={searchInput} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoadingAndErrIndicator err={err} isLoading={isLoading} loadingMessage={"Fetching products..."} />
            {!isLoading && !err && (<table className="table table-text-center">
                <thead className="thead-light">
                    <tr>
                        <th>
                            <input type="checkbox" className="form-check-input" checked={selectAllProducts}
                                onChange={event => selectAll(event.target.checked)}
                            />
                        </th>
                        <th scope="col">#</th>
                        <th scope="col">Type</th>
                        <th scope="col">Name</th>
                        <th scope="col">Company</th>
                        <th scope="col">GTIN</th>
                        <th scope="col">Creation date</th>
                    </tr>
                </thead>
                <tbody>
                    {searchData.map((product) => {
                        return (<tr style={{ backgroundColor: selectedProducts.includes(product.id) ? "rgb(184, 245, 184)" : "white" }}>
                            <td>
                                <input type="checkbox" className="form-check-input" checked={selectedProducts.includes(product.id)}
                                    onChange={event => selectProduct(product.id, event.target.checked)}
                                />
                            </td>
                            <td scope="row">{product.number}</td>
                            <td><i style={{ ...(product.is_optimized && { color: "#089c44" }) }} className={product.is_optimized ? "bi bi-tools" : "bi bi-tag"} /></td>
                            <td>
                                <Link to={"../product/" + product.id}>
                                    <a className="nav-link" href="#">
                                        {product.name}
                                    </a>
                                </Link>
                            </td>
                            <td>{product.company_name ?? "-"}</td>
                            <td>{product.gtin ?? "-"}</td>
                            <td>{product.createdAt ? moment(product.createdAt).format('D.M.YYYY') : "-"}</td>
                        </tr>)
                    })}
                </tbody>
            </table>)}
        </div>
    </>)
}

export default ProductTable
