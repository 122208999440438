

const Headline = ({ id, label, prefix}) => {
    return (
        <>
            <h2 id={id} style={{ marginTop: "45px", marginBottom: "15px"}}>{label + " " + (prefix ? `(${prefix})` : "")}</h2>
        </>
    );
}

export default Headline;