import LargeModal from "../general/LargeModal"
import ProductResultTable from "./ProductResultTable"

const SelectedResultsModal = ({ handleClose, results, maxScore, productImages, loadingProductImages, scoreIds, benchmark, selectedResults, setSelectedResults }) => {
    return (
        <LargeModal title={"Result Comparisons"} handleClose={handleClose}>
            <div style={{ overflow: "scroll" }}>
                <ProductResultTable canSelect={false} results={results} maxScore={maxScore} productImages={productImages} loadingProductImages={loadingProductImages} scoreIds={scoreIds} benchmark={benchmark} selectedResults={selectedResults} setSelectedResults={setSelectedResults} />
            </div></LargeModal>
    )
}

export default SelectedResultsModal