

const Loading = ({ desc }) => {
    return (<div className="mx-auto text-center">
        <div className="mx-auto" style={{ height: "100%", width: "36px" }}>
            <div className="lds-ripple mx-auto" style={{ margin: 0 }}><div style={{ border: "2px solid #089c44" }}></div><div></div></div>
        </div>
        {desc && <h6>{desc}</h6>}
    </div>
    );
}

export default Loading;