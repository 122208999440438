import { useState } from "react";

const TabView = ({ tabs }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    return (<>
        <div className="text-center mt-4" style={{ width: "100%", marginTop: "10px" }}>
            <ul className="nav nav-segment nav-pills mb-4" role="tablist">
                {tabs.map((tab) => {
                    return (
                        <li className="nav-item">
                            <a
                                onClick={function (e) { setSelectedTab(tab.index); }}
                                class={"nav-link" + (tab.index === selectedTab ? " active" : "")}
                                id="nav-three-eg1-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#nav-three-eg1"
                                role="tab" aria-controls="nav-three-eg1"
                                aria-selected={tab.index === selectedTab ? "true" : "false"}
                                style={{ cursor: "pointer" }}
                            >
                                {tab.name}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
        {tabs[selectedTab].tab}
        </>)
}

export default TabView