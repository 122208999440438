import LabTable from "./LabTable.js";
const Labs = () => {
    return (
        <div style={{ width: "100%" }}>
            <LabTable/>
        </div>
    )
}

export default Labs;
