

const Error = ({ err }) => {
    return (
        err ? (
            <div className={err ? "alert alert-soft-danger" : "offscreen"} role="alert">
                {err}
            </div>
        ) : (<></>)
    );
}


export default Error;