import axios from 'axios';

var ax = axios.create({
    //baseURL: "http://localhost:8080"
    baseURL: "https://api.susy.ehanelt.com"
});
/*
ax.interceptors.request.use(request => {
    console.log('Starting Request', JSON.stringify(request, null, 2))
    return request
})
  
ax.interceptors.response.use(response => {
console.log('Response:', JSON.stringify(response, null, 2))
return response
})*/

export default ax;
