import React from 'react'
import Input from './Input'
import Select from './Select'
import CheckBox from './CheckBox'
import Headline from './Headline'

const FormElement = ({ field: { info, prefix, ty, id, label } }) => {
    if (ty === "Headline") {
        return (<Headline
            prefix={prefix}
            id={id}
            label={label}
        />)
    }
    if ("Select" in ty) {
        return (<Select
            prefix={prefix}
            info={info}
            id={id}
            label={label}
            placeholder={ty.Select.placeholder}
            value={ty.Select.value}
            options={ty.Select.options}
        />)
    }
    if ("TextInput" in ty) {
        return (<Input
            prefix={prefix}
            info={info}
            id={id}
            label={label}
            placeholder={ty.TextInput.placeholder}
            value={ty.TextInput.value}
        />)
    }
    if ("CheckBox" in ty) {
        return (<CheckBox
            prefix={prefix}
            id={id}
            label={label}
            value={ty.CheckBox.value}
        />)
    }
    return null;
}

export default FormElement