


const SearchBar = ({handleChange, searchInput}) => {
    return (
        <div className="input-group input-group-merge">
                <input
                    type="text"
                    className="js-form-search form-control"
                    placeholder="Search..."
                    onChange={handleChange}
                    value={searchInput}
                    data-hs-form-search-options='{
                    "clearIcon": "#clearIcon2",
                    "defaultIcon": "#defaultClearIconToggleEg"
                    }'
                />
                <button type="button" className="input-group-append input-group-text">
                    <i id="clearIcon2" className="bi-x-lg" style={{ display: "none" }} />
                    <i
                        id="defaultClearIconToggleEg"
                        className="bi-search"
                        style={{ display: "false" }}
                    />
                </button>
            </div>
    )
}


export default SearchBar;