
import React, { useContext } from 'react'
import { FormContext } from '../../general/FormContext';
const CheckBox = ({rowId, id, label, value }) => {
    const { handleChange } = useContext(FormContext)
    return (
        <div className="form-check" style={{textAlign: "center", verticalAlign: "middle"}}>
            <input type="checkbox" className="form-check-input" id={id} checked={value} style={{margin: "auto", display: "block"}}
                onChange={event => handleChange(rowId, id, event)}
            />
        </div>
    )
}

export default CheckBox