
import React, { useContext } from 'react'
import { FormContext } from '../general/FormContext';
const CheckBox = ({ id, label, value, prefix }) => {
    const { handleChange } = useContext(FormContext)
    return (
        <div className="mb-3 form-check">
            <input type="checkbox" className="form-check-input" id={id} checked={value}
                onChange={event => handleChange(id, event)}
            />
            <label className="form-check-label" htmlFor={id}>{label + " " + (prefix ? `(${prefix})` : "")}</label>
        </div>
    )
}

export default CheckBox