import { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import Loading from "../../general/status/Loading";
import Error from "../../general/status/Error";
import Modal from "../../general/Modal";
import LoadingAndErrIndicator from "../../general/status/LoadingAndErrorIndicator";

const NEW_LAB_URL = '/admin/new-lab';

const NewLabModal = ({ handleClose }) => {
  const { auth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [labName, setLabName] = useState("");

  const createLab = async () => {
    setIsLoading(true);
    try {
        const { data } = await axios.post(
            NEW_LAB_URL,
            {
              name: labName
            },
            {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        );
    } catch (err) {
        setErr(err.message);
    } finally {
        setIsLoading(false);
    }
};

  return (
        <Modal title={"Create Lab"} handleClose={handleClose}>
          <div className="modal-body">
            {err || isLoading ? (<LoadingAndErrIndicator err={err} isLoading={isLoading} loadingMessage={"Creating Lab"}/>) : (<form>
              <div>
                <p>Create a new lab with the following name</p>
                <label className="form-label" htmlFor="signinSrEmail">
                    Name
                  </label>
                  <input
                    type="text"
                    key="lab-name"
                    className="form-control form-control-lg"
                    name="username"
                    id="signinSrEmail"
                    onChange={(e) => setLabName(e.target.value)}
                    tabIndex={1}
                    value={labName}
                    placeholder=""
                    aria-label="00000"
                    required=""
                  />
              </div>
            </form>)}
          </div>
          <div className="modal-footer">
            <button type="button" onClick={handleClose} className="btn btn-white" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" onClick={() => createLab()} className="btn btn-primary">Labor erstellen</button>
          </div>
        </Modal>
  );
}

export default NewLabModal;