import { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import Loading from "../../general/status/Loading";
import Error from "../../general/status/Error";
import { ROLES } from "../../../App";
import Modal from "../../general/Modal";
import GhostError from "../../general/status/GhostError";

const INVITE_USER_URL = '/admin/invite-user';
const LABS_URL = '/admin/labs';

const InviteUserModal = ({ handleClose }) => {
  const { auth } = useAuth();

  const [invitationData, setInvitationData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [copied, setCopied] = useState(false);
  const [role, setRole] = useState(0);
  const [labData, setLabData] = useState([]);
  const [lab, setLab] = useState(null);


  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const inviteUser = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        INVITE_USER_URL,
        {
          role: parseInt(role),
          labId: parseInt(lab),
        },
        {
          headers: {
            'Authorization': `Bearer ${auth.accessToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      setInvitationData(data);
      setErr(null)
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getLabs();
  }, []);

  const getLabs = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        LABS_URL,
        {
            headers: {
                'Authorization': `Bearer ${auth.accessToken}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        },
    );
      setLabData(data);
      setLab(data[0]?.id);
    } catch (err) {
        setErr(err.message);
    } finally {
        setIsLoading(false);
    }
  }

  const copy_to_clipboard = async () => {
    navigator.clipboard.writeText(`https://susy.ehanelt.com/signup/${invitationData.longIdentifier}`);
    setCopied(true);
    await sleep(2000);
    setCopied(false);
  }

  const Body = () => {
    return (<div className="text-center">
      <p>
      To invite a new user, please copy the code below and submit it. Once your invited customer enters this code, they can begin the registration process and create an account.</p>
      <h1 className="display-5" style={{letterSpacing: "10px"}}>{invitationData.shortIdentifier}</h1>
      <p>
      or just copy the text
      </p>
      <div className="input-group input-group-merge">
        <input
          type="text"
          id="tooltipExample"
          className="form-control"
          readOnly="true"
          value={"https://susy.ehanelt.com/signup/" + invitationData.longIdentifier}
        />
        <a
          className="js-clipboard input-group-append input-group-text"
          href="javascript:;"
          onClick={() => copy_to_clipboard()}
          data-bs-toggle="tooltip"
          title="Copy to clipboard!"
          data-hs-clipboard-options='{
            "type": "tooltip",
            "successText": "Copied!",
            "contentTarget": "#tooltipExample"
                }'
        >
          <i className={copied ? "bi bi-check2" : "bi-clipboard"} />
        </a>
      </div>
    </div>)
  }

  const RoleSelection = () => {
    return (
      <div>
        {/*<p>Create Invite</p>*/}
        <div className="row justify-content-between align-items-center" style={{paddingBottom: "20px"}}>
          <div className="mb-4">
            <label className="form-label" htmlFor="signinSrEmail">
              Role
            </label>
            <select className="form-select" onChange={event => setRole(event.target.value)} defaultValue={role}>
              <option value={0}>Admin</option>
              <option value={1}>Labor</option>
              <option value={2}>Kunde</option>
            </select>
          </div>
          {role == ROLES.Laboratory && <LabSelector />}
            <button type="button" onClick={() => inviteUser()} className="btn btn-ghost-primary">
              Generate Invite
            </button>
        </div>
      </div>
    );
  }

  const LabSelector = () => {
    return (
      <div className="mb-4">
        <label className="form-label" htmlFor="signinSrEmail">
          Assign Lab
        </label>
        {labData.length < 1 ? (<a className="text-danger">There are no laboratories yet</a>) : (
          <select className="form-select" onChange={event => setLab(event.target.value)} defaultValue={lab}>
            {labData.map((lab) => <option value={lab.id}>{lab.name}</option>)}
          </select>
        )}
      </div>
    );
  }

  return (
    <Modal title={"Create Invite"} handleClose={handleClose}>
          <div className="modal-body">
            <RoleSelection/>

            {(isLoading || err || invitationData.shortIdentifier) && (isLoading ? <Loading desc={"Creating invite..."}/> : (err ? <GhostError err={err} /> : <Body />))}
          </div>
          <div className="modal-footer">
            <button type="button" onClick={handleClose} className="btn btn-white" data-bs-dismiss="modal">
              Close
            </button>
          </div>
    </Modal>
  );
}

export default InviteUserModal;