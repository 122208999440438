
const GhostError = ({ err }) => {
    return (<div className="mx-auto text-center">
        <span className="icon icon-soft-danger mb-2">
            <i class="bi bi-bug"></i>
        </span>
        <h6 style={{ color: "#ed4c78" }}>Error: {err ?? "unknown"}</h6>
    </div>)
}

export default GhostError;