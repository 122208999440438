import { useContext, useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import moment from "moment";
import SearchBar from "../../general/SearchBar";
import Loading from "../../general/status/Loading";
import GhostError from "../../general/status/GhostError";
import NewLabModal from "./NewLabModal";
import LoadingAndErrIndicator from "../../general/status/LoadingAndErrorIndicator";

const LABS_URL = '/admin/labs';

const LabTable = () => {
    const { auth } = useAuth();
    const [labData, setLabData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState("");
    const [err, setErr] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        getLabs();
    }, []);

    const getLabs = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(
                LABS_URL,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            const mappedData = data
                .sort((a, b) => (a.name > b.name) ? 1 : -1)
                .map((lab, i) => {
                    return {
                        number: i + 1,
                        id: lab.id,
                        name: lab.name,
                    }
                })

            setSearchData(mappedData);
            setLabData(mappedData);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        if (e.target.value.length > 0) {
            let results = labData.filter((lab) => {
                return matches(lab, e.target.value);
            });
            setSearchData(results)
        } else {
            setSearchData(labData)
        }
    };

    function matches(lab, search) {
        const name = lab.name.trim();
        return name.includes(search.trim().toLowerCase());
    }

    return (<>
        {isModalOpen && <NewLabModal handleClose={() => setIsModalOpen(false)} />}
        <div className="card mb-3 mb-lg-5">
            <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                    <div className="col-md">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 class="card-header-title">Labs</h3>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="row align-items-sm-center">
                            <div className="col-sm-auto">
                                <button type="button" onClick={() => setIsModalOpen(true)} className="btn btn-ghost-primary">
                                    <i class="bi bi-clipboard-plus"></i> Create Lab
                                </button>
                            </div>
                            <div className="col-md">
                                <SearchBar handleChange={handleChange} searchInput={searchInput} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoadingAndErrIndicator err={err} isLoading={isLoading} loadingMessage={"Fetching Labs..."}/>
            {!isLoading && !err && (<table className="table table-text-center">
                <thead className="thead-light">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                    </tr>
                </thead>
                <tbody>
                    {searchData.map((lab) => {
                        return (<tr>
                            <th scope="row">{lab.number}</th>
                            <td>
                                <Link to={"./" + lab.id}>
                                    <a className="nav-link" href="#">
                                        {lab.name}
                                    </a>
                                </Link>
                            </td>
                        </tr>)
                    })}
                </tbody>
            </table>)}
        </div>
    </>)
}

export default LabTable