import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';


import logo from "../assets/img/susy_logo.png";
import googleicon from "../assets/svg/brands/google-icon.svg";
import backgroundImage from "../assets/img/background.png";


import axios from '../api/axios';
const FORGOTPASSWORD_URL = '/forgotpassword';

const ForgotPassword = () => {
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(FORGOTPASSWORD_URL,
                JSON.stringify({ email }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            console.log(JSON.stringify(response?.data));
            setEmail('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Invalid Username' + err);
            }
            errRef.current.focus();
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <main id="content" role="main" className="main">
          <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          top: 0,
          left: 0,
          height: "100%",
          backgroundColor: "#f3f5f7",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover'
        }}
      >
      </div>
    {/* Content */}
    <div className="container py-5 py-sm-7">
      <Link to="/home"><a
        className="d-flex justify-content-center mb-5"
      >
        <img
          className="zi-2"
          src={logo}
          alt="Image Description"
          style={{ width: "8rem" }}
        />
      </a></Link>
      <div className="mx-auto" style={{ maxWidth: "30rem" }}>
        {/* Card */}
        <div className="card card-lg mb-5">
          <div className="card-body">
            {/* Form */}
            <form className="js-validate needs-validation" noValidate="" onSubmit={handleSubmit}>
              <div className="text-center">
                <div className="mb-5">
                  <h1 className="display-5">Forgot Password?</h1>
                  <div ref={errRef} className={errMsg ? "alert alert-soft-danger" : "offscreen"} role="alert">
                    {errMsg}
                    </div>
                  <p>
                  No problem 🙅‍♂️. Just enter your email here and we will get back to you soon.{" "}
                  </p>
                <label
                  className="form-label w-100"
                  htmlFor="signupSrPassword"
                  tabIndex={0}
                >
                  <span className="align-items-center">
                    <Link to="/login"><a
                      className="form-label-link mb-0"
                    >
                      Back to login
                    </a></Link>
                  </span>
                </label>
                </div>
                {/*<span className="divider-center text-muted mb-4">OR</span>*/}
              </div>
              {/* Form */}
              <div className="mb-4">
                <label className="form-label" htmlFor="signinSrEmail">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="username"
                  ref={userRef}
                  id="signinSrEmail"
                  onChange={(e) => setEmail(e.target.value)}
                  tabIndex={1}
                  value={email}
                  placeholder=""
                  aria-label="00000"
                  required=""
                />
                <span className="invalid-feedback">
                  Bitte gebe eine valide Email-Adresse ein.
                </span>
              </div>
              {/* End Form */}
              {/* Form */}
              {/* End Form */}
              {/* Form Check */}
              {/* End Form Check */}
              <div className="d-grid">
                <button type="submit" className="btn btn-primary btn-lg" style={{height: "50px"}}>
                   {isLoading ? <div className="lds-ripple"><div></div><div></div></div> : "Anfragen"}
                </button>
              </div>
            </form>
            {/* End Form */}
          </div>
        </div>
        {/* End Card */}
      </div>
    </div>
    {/* End Content */}
  </main>
    )
}

export default ForgotPassword
