import JobTable from "./JobTable";

const Jobs = () => {

    return (
        <div style={{ width: "100%" }}>
            <JobTable />
        </div>
    );
}

export default Jobs;