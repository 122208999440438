import React, { useContext } from 'react'
import { FormContext } from '../../general/FormContext';

const Input = ({ rowId, id, label, placeholder, value }) => {
    const { handleChange } = useContext(FormContext)
    return (

        <div className="input-group input-group-borderless input-group-flush">
        <input
            type="text"
            className="form-control"
            value={value}
            placeholder={placeholder ? placeholder : ''}
            onChange={event => handleChange(rowId, id, event)}
            style={{
                width: `${(value?.length ?? 1) + 4}ch`,
                textAlign: "center",
                padding: '5px',
            }}
        />
        </div>
    )
}

export default Input