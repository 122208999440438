import { useContext, useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import useAuth from "../../../../hooks/useAuth";
import axios from "../../../../api/axios";
import Loading from "../../../general/status/Loading";
import LoadingAndErrIndicator from "../../../general/status/LoadingAndErrorIndicator";
import SearchBar from "../../../general/SearchBar";


const ProductUsers = () => {
    const { auth } = useAuth();
    const { productId } = useParams();

    const [userData, setUserData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState("");
    const [err, setErr] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectAllUsers, setSelectAllUsers] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        getUsers();
    }, []);

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        if (e.target.value.length > 0) {
            let results = userData.filter((user) => {
                return matches(user, e.target.value);
            });
            setSearchData(results)
        } else {
            setSearchData(userData)
        }
    };

    function matches(user, search) {
        const email = user.email.trim();
        return email.includes(search.trim().toLowerCase());
    }

    const addUser = async () => {
        setIsLoading(true);
        const URL = `/products/${productId}/add-user`;
    }

    const removeUsers = async () => {
        setIsLoading(true);
        const URL = `/products/${productId}/remove-user`;
        try {
            const { data } = await axios.post(
                URL,
                {
                    userIds: selectedUsers
                },
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    }


    const getUsers = async () => {
        setIsLoading(true);
        const URL = `/products/${productId}/users`;
        try {
            const { data } = await axios.get(
                URL,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            let n = 0;
            const mappedData = data
                .sort((a, b) => (a.email > b.email) ? 1 : -1)
                .map((user) => {
                    n += 1;
                    return {
                        number: n,
                        id: user.id,
                        email: user.email,
                    }
                })

            setSearchData(mappedData);
            setUserData(mappedData);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const openInvitationModal = () => {
        setIsModalOpen(true);
    };

    const handeClose = () => {
        setIsModalOpen(false);
    };

    const deleteButton = () => {
        return (
            <a class="btn btn-outline-danger btn-sm" href="javascript:;">
                <i class="bi-trash"></i> Delete
            </a>
        );
    }


    const selectUser = (id, checked) => {
        console.log("select user with id: " + id);
        const userIsSelected = selectedUsers.find(u => u === id);
        if (checked && !userIsSelected) {
            setSelectedUsers(selectedUsers => [...selectedUsers, id]);
        } else if (!checked && userIsSelected) {
            setSelectedUsers(selectedUsers.filter(u => u !== id));
        }
    }

    const selectAll = (checked) => {
        setSelectAllUsers(checked);
        if (checked) {
            setSelectedUsers(userData.map(u => u.id));
        } else {
            setSelectedUsers([]);
        }
    }

    const Selection = () => {
        return (
            <div className="d-flex align-items-center">
                <span className="fs-5 me-3">
                    <span id="datatableCounter">{selectedUsers.length + " "}</span>
                    selected
                </span>
                <a className="btn btn-outline-danger btn-sm" href="javascript:;" onClick={() => removeUsers()}>
                    <i className="bi-trash" /> Delete
                </a>
            </div>
        );
    }


    const NoCustomers = () => {
        return (<div className="my-4">
            <div className="mx-auto text-center">
                <span className="icon icon-soft-danger mb-2">
                    <i class="bi bi-bug"></i>
                </span>
                <h6 style={{ color: "#ed4c78" }}>No customers assigned yet.</h6>
            </div>
        </div>)
    }


    return (
        <div style={{ width: "100%" }}>
            <div className="card">
            <div className="card-header">
                    <div className="row justify-content-between align-items-center flex-grow-1">
                        <div className="col-md">
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 class="card-header-title">Assigned Customers</h3>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="row align-items-sm-center">
                                <div className="col-sm-auto">
                                {selectedUsers.length > 0 && <Selection />}
                                </div>
                                <div className="col-auto py-1">
                                <button type="button" onClick={() => console.log("Assign Customer")} className="btn btn-ghost-primary">
                                        <i class="bi bi-clipboard-plus"></i> Assign Customer
                                    </button>
                                </div>
                                <div className="col-md">
                                <SearchBar handleChange={handleChange} searchInput={searchInput} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadingAndErrIndicator err={err} isLoading={isLoading} loadingMessage={"Fetching product users..."}/>
                {!isLoading && !err && (<table className="table table-text-center">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">
                                <input type="checkbox" className="form-check-input" checked={selectAllUsers}
                                    onChange={event => selectAll(event.target.checked)}
                                />
                            </th>
                            <th scope="col">#</th>
                            <th scope="col">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchData.map((user) => {
                            return (<tr>
                                <td>
                                    <input type="checkbox" className="form-check-input" checked={selectedUsers.includes(user.id)}
                                        onChange={event => selectUser(user.id, event.target.checked)}
                                    />
                                </td>
                                <td scope="row">{user.number}</td>
                                <td>
                                    <Link to={"../users/" + user.id}>
                                        <a className="nav-link" href="#">
                                            {user.email}
                                        </a>
                                    </Link>
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>)}
                {userData.length < 1  && !isLoading && !err ? <NoCustomers /> : <></>}
            </div>
        </div>
    );
}

export default ProductUsers;