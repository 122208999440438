import React from 'react'
import Input from './Input'
import Select from './Select'
import CheckBox from './CheckBox'

const FormElement = ({ rowId, field: {ty, id, label } }) => {
    if ("Select" in ty) {
        return (<Select
            rowId={rowId}
            id={id}
            label={label}
            placeholder={ty.Select.placeholder}
            value={ty.Select.value}
            options={ty.Select.options}
        />)
    }
    if ("TextInput" in ty) {
        return (<Input
            rowId={rowId}
            id={id}
            label={label}
            placeholder={ty.TextInput.placeholder}
            value={ty.TextInput.value}
        />)
    }
    if ("CheckBox" in ty) {
        return (<CheckBox
            rowId={rowId}
            id={id}
            label={label}
            value={ty.CheckBox.value}
        />)
    }
    return null;
}

export default FormElement