import { Link } from "react-router-dom"

const Unauthorized = () => {

    return (
        <article style={{ padding: "100px" }}>
            <h1>Uups!</h1>
            <p>Du bist nicht befugt, auf diese Seite zuzugreifen.</p>
            <div className="flexGrow">
                <Link to="..">Gehe zurück</Link>
            </div>
        </article>
    )
}

export default Unauthorized
