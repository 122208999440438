import React, { useContext, useState, useEffect } from 'react'
import { FormContext } from '../general/FormContext';
const Select = ({ id, label, placeholder, value, options, prefix, info }) => {
    const { handleChange } = useContext(FormContext);
    const [isNull, setIsNull] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);

  useEffect(() => {
    setIsNull(value === null)
  }, [])


    return (
        <>
            <label className="form-label">{label + " " + (prefix ? `(${prefix})` : "")}</label>
            {info && <i class={infoOpen ? "bi bi-x-circle" : "bi bi-info-circle"} onClick={() => setInfoOpen(infoOpen => !infoOpen)} style={{ color: "rgb(8, 156, 68)", fontSize: "1rem", verticalAlign: "middle", marginLeft: "5px" }} />}
            {infoOpen && <p>{info.split('\n').map(str => <>{str}<br></br></>)}</p>}
            <select className="form-select mb-3" aria-label="" value={value} style={{borderColor: isNull ? "red" : "rba(231, 234, 243, 0.7)"}}
                onChange={event => {
                    //console.log("clicked on:" + event.target.value)
                    //setIsNull(false);
                    //setIsNull(event.target.value !== 1337);
                    handleChange(id, event)
                }}
            >
                <option value={1337}>{placeholder ?? "Bitte auswählen..."}</option>
                {options.length > 0 && options.map((option, i) =>
                    <option value={option.id} key={i} >{option.name}</option>

                )}
            </select>
        </>
    )
}

export default Select