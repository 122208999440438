import { Link } from "react-router-dom"

const Home = () => {
    return (
        <article style={{ padding: "100px" }}>
            <h1>Home</h1>
            <a>Already have an account?</a>
            <div className="mb-3">
                <Link to="/login">Go to login</Link>
            </div>
            <a>Don't have an account yet?</a>
            <div className="">
                <Link to="/signup">Go to signup</Link>
            </div>
        </article>
    )
}

export default Home