import placeholderImage from "../../../assets/img/placeholder.jpg";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import moment from "moment";

const VERSIONS = ['V1', 'V2'];

const ProductInfo = () => {
    const { auth } = useAuth();
    const { productId } = useParams();
    const [err, setErr] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [productInfo, setProductInfo] = useState(null);
    const [image, setImage] = useState(placeholderImage);

    useEffect(() => {
        getProductInfo();
        getImage();
    }, []);

    const getImage = async () => {
        const URL = `/products/${productId}/image`;
        await axios.get(URL, {
            responseType: "arraybuffer",
            headers: {
                'Authorization': `Bearer ${auth.accessToken}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((res) => {
                const base64 = btoa(
                    new Uint8Array(res.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                )
                setImage(`data:;base64,${base64}`)
            }).catch((error) => {
                console.log("Loading image error: " + error);
            });
    }

    const uploadImage = async (image) => {
        const URL = `/products/${productId}/image/upload`;
        let data = new FormData();
        data.append('file', image, image.name);

        axios.post(URL, data, {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                'Authorization': `Bearer ${auth.accessToken}`,
            }
        })
            .then((response) => {
                setErr(null);
                getImage();
            }).catch((error) => {
                setErr("Error uploading image: " + error.response?.data);
            });
    }

    const getProductInfo = async () => {
        setIsLoading(true);
        try {
            const URL = `/products/${productId}/info`
            const { data } = await axios.get(
                URL,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            setProductInfo(data);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const updateVersion = async (newVersion) => {
        setIsLoading(true);
        try {
            const URL = `/products/${productId}/algo/update`
            const { data } = await axios.post(
                URL,
                newVersion,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            setProductInfo(data);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <div className="card">
            <div className="row no-gutters">
                <div className="col-md-auto">
                    <img
                        height={"200px"}
                        src={image}
                        alt="Productimage"
                    />
                </div>
                <div className="col-md-8">
                    <div className="card-body">
                        {productInfo?.is_optimized ? (<h5 style={{ color: "#089c44" }}><i className="bi bi-tools" /> DIGITAL TWIN</h5>)
                            : (<h5 class="card-subtitle mb-2">PRODUCT</h5>)}
                        <h3 className="card-title" style={{ marginBottom: "0px" }}>{productInfo?.name ?? "Product"}</h3>
                        {/*<div className="form-check">
                        <input type="checkbox" id="123434" className="form-check-input"></input>
                        <label className="form-check-label" htmlFor="123434">is optimized</label>
                    </div>*/}
                        <label for="file" style={{ color: "#089c44", cursor: "pointer", padding: "" }}>{image === placeholderImage ? "Upload image" : "Change image"}
                            <input
                                id="file"
                                type="file"
                                style={{ visibility: "hidden", height: "0px", width: "0px" }}
                                name="myImage"
                                onChange={(event) => {
                                    uploadImage(event.target.files[0]);
                                }}
                            />
                        </label><br></br>
                        <a>{"Company: " + (productInfo?.company_name ?? " - ")}</a><br></br>
                        <a>{"GTIN: " + (productInfo?.gtin ?? " - ")}</a><br></br>
                        <a>{"Algorithm-Version: "}</a><br></br>
                        {productInfo && <div className="" style={{ width: "100%", float: "left" }}>
                            <ul className="nav nav-segment nav-pills" role="tablist">
                                {VERSIONS.map((version) => {
                                    return (
                                        <li className="nav-item">
                                            <a
                                                onClick={() => { updateVersion(version); }}
                                                class={"nav-link" + (productInfo?.algorithm_version === version ? " active" : "")}
                                                id="nav-three-eg1-tab-2"
                                                data-bs-toggle="pill"
                                                data-bs-target="#nav-three-eg1"
                                                role="tab" aria-controls="nav-three-eg1"
                                                aria-selected={productInfo?.algorithm_version === version ? "true" : "false"}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {version}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>}
                        <small className="text-muted">{productInfo?.createdAt ? ("created at: " + moment(productInfo.createdAt).format('D.M.YYYY')) : ""}</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductInfo
