import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import LoadingAndErrIndicator from "../general/status/LoadingAndErrorIndicator";
import Modal from "../general/Modal"

const NEW_PRODUCT_URL = '/products/new';

const AddProductModal = ({ handleClose, jobId }) => {
    const { auth } = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');
    const [productName, setProductName] = useState("");
    const navigate = useNavigate();

    const createProduct = async () => {
      setIsLoading(true);
      try {
          const { data } = await axios.post(
              NEW_PRODUCT_URL,
              {
                  jobId: jobId ? parseInt(jobId) : null,
                  name: productName
              },
              {
                  headers: {
                      'Authorization': `Bearer ${auth.accessToken}`,
                      'Content-Type': 'application/json',
                      Accept: 'application/json',
                  },
              },
            );
          navigate(`../product/${data.id}`)
        } catch (err) {
          setErr(err.message);
      } finally {
          setIsLoading(false);
      }
  };

    return (
        <Modal title={"Create Product"} handleClose={handleClose}>
        <div className="modal-body">
            <form>
              <div>
                <p>Erstelle ein neues Produkt mit folgendem Namen</p>
                <label className="form-label" htmlFor="signinSrEmail">
                    Name
                  </label>
                  <input
                    type="text"
                    key="lab-name"
                    className="form-control form-control-lg"
                    name="username"
                    id="signinSrEmail"
                    onChange={(e) => setProductName(e.target.value)}
                    tabIndex={1}
                    value={productName}
                    placeholder=""
                    aria-label="00000"
                    required=""
                  />
              </div>
            </form>
            {<LoadingAndErrIndicator err={err} isLoading={isLoading} loadingMessage={"Creating product..."}/>}
          </div>
          <div className="modal-footer">
            <button type="button" onClick={handleClose} className="btn btn-white" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" onClick={() => createProduct()} className="btn btn-primary">Create Product</button>
          </div>
        </Modal>
    )
}

export default AddProductModal