import ForgotPassword from './components/ForgotPassword';
import Login from './components/Login';
import Home from './components/Home';
import Layout from './components/Layout';
import Missing from './components/general/status/Missing';
import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import Dashboard from './components/user/Dashboard';
import Address from './components/user/Address';
import Users from './components/admin/users/Users';
import Products from './components/products/Products';
import Jobs from './components/jobs/Jobs';
import Job from './components/jobs/Job';
import Signup from './components/signup/Signup';
import User from './components/admin/User'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import React  from 'react';


import useScript from './hooks/useScript';
import useAuth from './hooks/useAuth';
import { useEffect, useContext } from "react";
import AuthContext from "./context/AuthProvider";
import Labs from './components/admin/labs/Labs';
import Lab from './components/admin/Lab';
import Materials from './components/admin/Materials';
import Weighting from './components/admin/Weighting';
import JobTable from './components/jobs/JobTable';
import Product from './components/products/product/Product';
export const ROLES = {
  'Admin': 0,
  'Laboratory': 1,
  'Customer': 2,
}

function App() {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //const from = location.state?.from?.pathname || "/";
  const auth_string = localStorage.getItem("auth");
  useEffect(() => {
    const auth = JSON.parse(auth_string);
    if (auth) {
      setAuth(auth);
    }
    if (auth_string && location.pathname === '/') {
      if (auth.role === ROLES.Admin) {
        navigate('admin/', { replace: true });
      } else if (auth.role === ROLES.Laboratory) {
        navigate('lab/', { replace: true });
      } else if (auth.roles === ROLES.Customer) {
        navigate('customer/', { replace: true });
      }
    } else {
      navigate(location.pathname, { replace: true });
    }
  }, [auth_string]);


  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="signup/:invite_id?" element={< Signup />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* we want to protect these routes */}

        <Route path="/admin" element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="" element={< Dashboard role = {ROLES.Admin} />}>
            <Route path="users" element={< Users />} />
            <Route path="users/:userId" element={< User />} />
            <Route path="labs" element={< Labs />} />
            <Route path="labs/:labId" element={< Lab />} />
            <Route path="" element={< Jobs />} />
            <Route path="product/:productId" element={< Product role = {ROLES.Admin} />}/>
            <Route path="products/" element={< Products />}/>
            <Route path="jobs/:jobId" element={< Job />}/>
            <Route path="address" element={< Address />} />
            <Route path="materials" element={< Materials />} />
            <Route path="weighting" element={< Weighting />} />
          </Route>
        </Route>

        <Route path="/lab" element={<RequireAuth allowedRoles={[ROLES.Laboratory]} />}>
          <Route path="" element={< Dashboard role = {ROLES.Laboratory} />}>
            <Route path="jobs" element={< Jobs />} />
            <Route path="jobs/:jobId" element={< Job />} />
            <Route path="product/:productId" element={< Product role = {ROLES.Laboratory}/>}/>
            <Route path="products/" element={< Products />}/>
            <Route path="address" element={< Address />} />
          </Route>
        </Route>


        <Route path="/customer" element={<RequireAuth allowedRoles={[ROLES.Customer]} />}>
          <Route path="" element={< Dashboard role = {ROLES.Customer} />}>
            <Route path="" element={< Jobs />} />
            <Route path="jobs/:jobId" element={< Job />}/>
            <Route path="product/:productId" element={< Product role = {ROLES.Customer} />}/>
            <Route path="products/" element={< Products />}/>
            <Route path="address" element={< Address />} />
          </Route>
        </Route>

        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;