import { useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import LabForm from "./lab/LabForm"
import ProductUsers from "./users/ProductUsers"
import ComponentsForm from "./ComponentsForm"
import Result from "./Result";
import placeholderImage from "../../../assets/img/placeholder.jpg";
import moment from "moment";
import { Link } from 'react-router-dom';
import Error from "../../general/status/Error";
import Lab from "./lab/Lab";
import { ROLES } from "../../../App";
import TabView from "../../general/TabView";
import ProductForm from "./ProductForm";
import ProductInfo from "./ProductInfo";

const Product = ({ role }) => {
    const { productId } = useParams();
    const [tabs, setTabs] = useState([
        { index: 0, name: "Company", tab: (<ProductForm />) },
        { index: 1, name: "Components", tab: (<ComponentsForm />) },
    ]);

    useEffect(() => {
        switch (role) {
            case ROLES.Admin:
                const newAdminTabs = [

                    { index: 0, name: "Company", tab: (<ProductForm />) },
                    { index: 1, name: "Components", tab: (<ComponentsForm />) },
                    { index: 2, name: "Lab", tab: (<Lab />) },
                    { index: 3, name: "Users", tab: (<ProductUsers />) },
                    { index: 4, name: "Result", tab: (<Result />) }
                ];
                setTabs(newAdminTabs)
                break;
            case ROLES.Laboratory:
                const newLabTabs = [
                    { index: 0, name: "Company", tab: (<ProductForm />) },
                    { index: 1, name: "Components", tab: (<ComponentsForm />) },
                    { index: 2, name: "Lab", tab: (<LabForm />) },
                ];
                setTabs(newLabTabs)
                break;
            case ROLES.Customer:
                break;
        }
    }, [role, productId]);


    return (
        <div style={{ width: "100%" }}>
            <ProductInfo />
            <TabView tabs={tabs} />
        </div>
    );
}


export default Product;
