import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import Loading from "../general/status/Loading";
import Modal from "../general/Modal"
import GhostError from "../general/status/GhostError";
import Warning from "../general/status/Warning";
import LoadingAndErrIndicator from "../general/status/LoadingAndErrorIndicator";

const REMOVE_PRODUCTS_URL = '/products/remove';

const RemoveProductsModal = ({ productIds, jobId, reload, handleClose }) => {
  const { auth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [productName, setProductName] = useState("");
  const navigate = useNavigate();

  const removeProducts = async (jobId) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        REMOVE_PRODUCTS_URL,
        {
          jobId: jobId ? parseInt(jobId) : null,
          productIds: productIds
        },
        {
          headers: {
            'Authorization': `Bearer ${auth.accessToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      reload();
      handleClose();
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal title={"Remove Products"} handleClose={handleClose}>
      <div className="modal-body">
        {err || isLoading ? (<LoadingAndErrIndicator err={err} isLoading={isLoading} loadingMessage={"Removing products..."} />) :
          (<Warning warning={`Do you really want to remove ${productIds.length} products`} />)}
      </div>
      <div className="modal-footer">
        {/*<button type="button" onClick={handleClose} className="btn btn-white" data-bs-dismiss="modal">
              Close
            </button>*/}

        <div class="row justify-content-between align-items-center mx-auto">
          {jobId && (<div class="col-auto py-1">
            <button type="button" onClick={() => removeProducts(jobId)} className="btn btn-outline-primary">Remove Products from Jobs</button>
          </div>)}

          <div class="col-auto py-1">
            <button type="button" onClick={() => removeProducts()} className="btn btn-outline-primary">Remove Products</button>
          </div>
        </div>
      </div>
    </Modal >
  )
}

export default RemoveProductsModal