


const Address = () => {
  return (
    <div style={{ width: "100%" }}>
      <div className="card mb-3">
        <div className="card-header">
          <h4 class="card-header-title">Personal Data</h4>
        </div>
        <div className="card-body">

          <div className="mb-3">
            <label htmlFor="inputGroupMergeFullName" className="form-label">
              Full name
            </label>
            <div className="input-group input-group-merge">
              <div
                className="input-group-prepend input-group-text"
                id="inputGroupMergeFullNameAddOn"
              >
                <i className="bi-person" />
              </div>
              <input
                type="text"
                className="form-control"
                id="inputGroupMergeFullName"
                placeholder="Mark Williams"
                aria-label="Mark Williams"
                aria-describedby="inputGroupMergeFullNameAddOn"
              />
            </div>
          </div>
          {/* End Input Group */}
          {/* Input Group */}
          <div className="mb-3">
            <label htmlFor="inputGroupMergeEmail" className="form-label">
              Email
            </label>
            <div className="input-group input-group-merge">
              <div
                className="input-group-prepend input-group-text"
                id="inputGroupMergeEmailAddOn"
              >
                <i className="bi-envelope-open" />
              </div>
              <input
                type="text"
                className="form-control"
                id="inputGroupMergeEmail"
                placeholder="mark@example.com"
                aria-label="mark@example.com"
                aria-describedby="inputGroupMergeEmailAddOn"
              />
            </div>
          </div>
          {/* End Input Group */}
          {/* Input Group */}
          <div className="mb-3">
            <label htmlFor="inputGroupMergeEmailOptional" className="form-label">
              Email <span className="form-label-secondary">(Optional)</span>
            </label>
            <div className="input-group input-group-merge">
              <input
                type="text"
                className="form-control"
                id="inputGroupMergeEmailOptional"
                placeholder="mark@example.com"
                aria-label="mark@example.com"
                aria-describedby="inputGroupMergeEmailOptionalAddOn"
              />
              <div
                className="input-group-append input-group-text"
                id="inputGroupMergeEmailOptionalAddOn"
              >
                <i className="bi-envelope-open" />
              </div>
            </div>
          </div>
          {/* End Input Group */}
          {/* Input Group */}
          <div className="mb-3">
            <label htmlFor="inputGroupMergeGenderSelect" className="form-label">
              Gender
            </label>
            <div className="input-group input-group-merge">
              <div className="input-group-prepend input-group-text">
                <i className="bi-person" />
              </div>
              <select id="inputGroupMergeGenderSelect" className="form-select">
                <option>Choose a gender</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div class="d-flex justify-content-end">
            <button type="button" onClick={() => console.log("Saving changes")} className="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Address;