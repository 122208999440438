


const Weighting = () => {


    return (
        <>
            <h1>Gewichtungen (bald verfügbar)</h1>
        </>
    )






}

export default Weighting