import { useState, useEffect } from "react"
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { FormContext } from "../../general/FormContext";
import FormElement from "../../form_elements/table/FormElement";
import { useParams } from "react-router-dom";
import Loading from "../../general/status/Loading";
import LoadingAndErrIndicator from "../../general/status/LoadingAndErrorIndicator";


const ComponentsForm = () => {

    const { productId } = useParams();

    const { auth } = useAuth();
    const [selectedTab, setSelectedTab] = useState(0);
    const [labId, setLabId] = useState(null);
    const [labName, setLabName] = useState(null);
    const [err, setErr] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [components, setComponents] = useState([]);
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [selectAllComponents, setSelectAllComponents] = useState(false);

    const getComponents = async () => {
        setIsLoading(true);
        const URL = `/products/${productId}/components`;
        try {
            const { data } = await axios.get(
                URL,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            console.log(data);
            setComponents(data);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getComponents();
    }, []);

    const handleChange = (rowId, fieldId, event) => {
        const newComponents = [...components]
        newComponents.map(component => {
            if (component.id !== rowId) {
                return component;
            }
            component.fields.forEach(field => {
                const { ty, id } = field;
                if (id === fieldId) {
                    if ("CheckBox" in ty) {
                        field.ty.CheckBox.value = event.target.checked;
                    } else if ("TextInput" in ty) {
                        field.ty.TextInput.value = event.target.value;
                    } else if ("Select" in ty) {
                        field.ty.Select.value = event.target.value;
                    }
                }
            });
        });
        setComponents(newComponents)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let component_updates = components.map(component => {
            const fields = component.fields.map((field) => {
                if (field.ty === "Headline") {
                    return null;
                }
                if ("TextInput" in field.ty) {
                    return { id: field.id, value: field.ty.TextInput.value }
                }
                if ("CheckBox" in field.ty) {
                    return { id: field.id, value: field.ty.CheckBox.value }
                }
                if ("Select" in field.ty) {
                    return { id: field.id, value: field.ty.Select.value !== "1337" ? `${field.ty.Select.value}` : 1337 }
                }
                return null
            })
            return { id: parseInt(component.id), fields: fields }
        })
        try {
            setIsLoading(true);
            const URL = `/products/${productId}/components/update`;
            const { data } = await axios.post(
                URL,
                component_updates,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            getComponents();
            setErr(null);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    }

    const addComponent = async () => {
        setIsLoading(true);
        const URL = `/products/${productId}/components/add`;
        try {
            const { data } = await axios.post(
                URL,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
        } catch (err) {
            setErr(err.message);
        } finally {
            getComponents();
        }
    }

    const deleteComponents = async () => {
        setIsLoading(true);
        const URL = `/products/${productId}/components/remove`;
        try {
            const { data } = await axios.post(
                URL,
                selectedComponents,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            setSelectAllComponents(false);
            setSelectedComponents([]);
        } catch (err) {
            setErr(err.message);
        } finally {
            getComponents();
        }
    }

    const selectComponent = (id, checked) => {
        const componentIsSelected = selectedComponents.find(u => u === id);
        console.log('component is selected:' + componentIsSelected);
        if (checked && !componentIsSelected) {
            setSelectedComponents(selectedComponents => [...selectedComponents, id]);
        } else if (!checked && componentIsSelected) {
            setSelectedComponents(selectedComponents => selectedComponents.filter(u => u !== id));
        }
    }

    const selectAll = (checked) => {
        setSelectAllComponents(checked);
        if (checked) {
            setSelectedComponents(components.map(c => c.id));
        } else {
            setSelectedComponents([]);
        }
    }

    const Selection = () => {
        return (
            <div className="d-flex align-items-center">
                <span className="fs-5 me-3">
                    <span id="datatableCounter">{selectedComponents.length + " "}</span>
                    selected
                </span>
                <a className="btn btn-outline-danger btn-sm" href="javascript:;" onClick={() => deleteComponents()}>
                    <i className="bi-trash" /> Delete
                </a>
            </div>
        );
    }


    const Weight = () => {
        const weight = (components.map((c) => c.fields.find((f) => f.id === "weight")).map((f) => parseFloat(f.ty.TextInput.value)).reduce((a, b) => a + b));
        return (
            <div className="d-flex align-items-center">
                <span className="fs-5 me-3">
                    Total Packaging Weight: {!isNaN(weight) ? weight.toFixed(2) : "0.00"}
                    g
                </span>
            </div>
        );
    }

    const NumOfComponents = () => {
        const count = components.reduce((acc, component) => acc + component.fields.filter(field => field.id === 'recyclate_of_another_component' && field.ty.CheckBox.value === false).length, 0);
        return (
            <div className="d-flex align-items-center">
                <span className="fs-5 me-3">
                    Number of Components: {count}
                </span>
            </div>
        );
    }

    const NoComponents = () => {
        return (<div className="my-4">
            <div className="mx-auto text-center">
                <span className="icon icon-soft-danger mb-2">
                    <i class="bi bi-bug"></i>
                </span>
                <h6 style={{ color: "#ed4c78" }}>No components registered yet.</h6>
            </div>
        </div>)
    }


    return (
        <div style={{ width: "100%" }}>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row justify-content-between align-items-center flex-grow-1">
                        <div className="col-md">
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 class="card-header-title">Components</h3>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="row align-items-sm-center">
                                <div className="col-sm-auto">
                                    {auth?.role != 2 && components.length > 0 && <Weight />}
                                </div>
                                <div className="col-sm-auto">
                                    <NumOfComponents />
                                </div>
                                <div className="col-auto py-1">
                                    {selectedComponents.length > 0 && <Selection />}
                                </div>
                                <div className="col-md">
                                    <button type="button" onClick={() => addComponent()} className="btn btn-ghost-primary">
                                        <i class="bi bi-clipboard-plus"></i> Add Component
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadingAndErrIndicator err={err} isLoading={isLoading} loadingMessage={"Fetching components..."}/>
                <FormContext.Provider value={{ handleChange }}>
                <form>
                {!isLoading && !err && (<table className="table table-text-center">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">
                                <input type="checkbox" className="form-check-input" checked={selectAllComponents}
                                    onChange={event => selectAll(event.target.checked)}
                                />
                            </th>
                            {["#", "Function", "Main Material", (auth?.role != 2 ? "Weight in g" : null), "Recyclate", "Recyclate in %", "Recyclate Subpart", "Origin of Recyclate", "Comment", "id",].map((header) => {
                                if (header == null) { return null }
                                return (
                                    <th scope="col">{header}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {components.map((component, n) => {
                            return (<tr>
                                <td>
                                    <input type="checkbox" className="form-check-input" checked={selectedComponents.includes(component.id)}
                                        onChange={event => selectComponent(component.id, event.target.checked)}
                                    />
                                </td>
                                <td scope="row" style={{textAlign: "center", verticalAlign: "middle"}}>{n + 1}</td>
                                {component.fields ? component.fields.map((field, i) => (<td><FormElement key={component.id + "13337" + i} rowId={component.id} field={field} /></td>)) : null}
                            </tr>)
                        })}
                    </tbody>
                </table>)}
                {components.length < 1 && !isLoading && !err ? <NoComponents /> : <></>}
                </form>
                <div class="card-footer"><div class="d-flex justify-content-end"><button type="button" class="btn btn-primary" onClick={(e) => handleSubmit(e)}>Save changes</button></div></div>
                </FormContext.Provider>
            </div>
        </div>
    )
}


export default ComponentsForm;