import { useParams } from "react-router-dom"


const Lab = () => {
    const { labId } = useParams();
    return (
        <div>
            <h1>Labor</h1>
            <a>{labId}</a>
        </div>
    )
}

export default Lab;